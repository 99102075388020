import { Button } from "@vaultinum/app-sdk";
import { BrowserNavigation } from "@vaultinum/vaultinum-legal-proof-api";
import classNames from "classnames";
import { Dispatch, SetStateAction } from "react";
import { LeftArrow, Notification, RightArrow } from "../../../../components";

export default function NavigationButtons({
    sessionStarted,
    leftArrowDisabled,
    rightArrowDisabled,
    navigationAction,
    setShowEvents
}: {
    sessionStarted: boolean;
    leftArrowDisabled: boolean;
    rightArrowDisabled: boolean;
    navigationAction: (action: BrowserNavigation) => void;
    setShowEvents: Dispatch<SetStateAction<boolean>>;
}) {
    if (!sessionStarted) {
        return null;
    }

    return (
        <div className="flex space-x-2">
            <Notification onClick={() => setShowEvents(prev => !prev)} />
            <Button
                className={classNames({ "cursor-not-allowed": leftArrowDisabled })}
                variant="iconButton"
                onClick={() => navigationAction(BrowserNavigation.BACK)}
                icon={<LeftArrow disabled={leftArrowDisabled} />}
                disabled={leftArrowDisabled}
            />
            <Button
                className={classNames({ "cursor-not-allowed": rightArrowDisabled })}
                variant="iconButton"
                onClick={() => navigationAction(BrowserNavigation.FORWARD)}
                icon={<RightArrow disabled={rightArrowDisabled} />}
                disabled={rightArrowDisabled}
            />
        </div>
    );
}
