import { useI18n, useToast } from "@vaultinum/app-sdk";
import { BrowserNavigation, InteractionEvent } from "@vaultinum/vaultinum-legal-proof-api";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DOWNLOADING_PAGE } from "../../../AppRouter";
import { useAuth } from "../../../auth";
import { useSession } from "../../../context";
import { useSessionHandlers } from "../../../hooks";
import { Translation } from "../../../i18n";
import { ActionStatus, IconType, Notifications } from "../../../model";
import { AreaDraw, initializeSocket } from "../../../tool";
import { EventHistory, NavigationButtons, RecordingTools, SessionDuration, Video } from "./components";

export default function MainPage() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { socket, setSocket, reportMetadata, sessionDuration, setSessionDuration } = useSession();
    const { error } = useToast();
    const { translation } = useI18n<Translation>();

    const userUid = user?.uid ?? "";
    const [remoteUrl, setRemoteUrl] = useState("https://");
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [sessionStarted, setSessionStarted] = useState(false);
    const [eventHistory, setEventHistory] = useState<Notifications.Event[]>([]);
    const [showEvents, setShowEvents] = useState(false);
    const [processingClick, setProcessingClick] = useState(false);
    const [actionsStatus, setActionsStatus] = useState<ActionStatus>({
        navigationButtons: true,
        endButton: true,
        areaIcon: IconType.DEFAULT
    });
    const [lastBrowserAction, setLastBrowserAction] = useState<BrowserNavigation>();
    const [progressionText, setProgressionText] = useState<string>("");
    const [errorDuringInitialization, setErrorDuringInitialization] = useState(false);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const handlers = useSessionHandlers(canvasRef, sessionStarted, userUid, {
        setSessionStarted,
        setProcessingClick,
        setActionsStatus,
        setProgressionText,
        setRemoteUrl,
        setSessionDuration,
        setEventHistory,
        setErrorDuringInitialization
    });

    async function startSession(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key !== "Enter" || !user) {
            return;
        }
        const token = await user.getIdToken();
        const initSocket = initializeSocket(token);
        initSocket.connect();
        setSocket(initSocket);
        const { width, height } = getDimensions();
        initSocket.emit(InteractionEvent.START, {
            userUid,
            url: remoteUrl,
            width: Math.floor(width),
            height: Math.floor(height),
            reportMetadata,
            browserLang: navigator.language.split("-")[0]
        });
    }

    async function endSession() {
        socket.emit(InteractionEvent.END, { userUid, sessionDuration });
        navigate(DOWNLOADING_PAGE);
    }

    const resizeAction = useCallback(
        (width: number, height: number) => {
            if (socket.active) {
                socket.emit(InteractionEvent.RESIZE, { userUid, width, height });
            }
        },
        [socket, userUid]
    );

    function navigationAction(action: BrowserNavigation) {
        setLastBrowserAction(action);
        socket.emit(InteractionEvent.BROWSER, { userUid, action });
    }

    function onArea() {
        if (actionsStatus.areaIcon === IconType.DEFAULT) {
            new AreaDraw(socket, userUid, setActionsStatus, "crosshair");
        } else {
            document.getElementById("new-canvas")?.remove();
        }

        setActionsStatus(prev => {
            return {
                ...prev,
                areaIcon: actionsStatus.areaIcon === IconType.ACTIVE ? IconType.DEFAULT : IconType.ACTIVE
            };
        });
    }

    useEffect(() => {
        if (!socket.active || !userUid) {
            return;
        }
        const handlePaste = (event: ClipboardEvent) => {
            const clipboardText = event.clipboardData?.getData("text");
            if (!clipboardText) {
                return;
            }
            socket.emit(InteractionEvent.PASTE, { userUid, text: clipboardText });
        };

        document.addEventListener("paste", handlePaste);

        return () => {
            document.removeEventListener("paste", handlePaste);
        };
    }, [socket, userUid]);

    useEffect(() => {
        if (!socket.active) {
            return;
        }

        handlers.forEach(({ eventName, onEvent }) => {
            socket.on(eventName, onEvent);
        });

        return () => {
            handlers.forEach(({ eventName, onEvent }) => {
                socket.off(eventName, onEvent);
            });
        };
    }, [handlers, socket]);

    useEffect(() => {
        if (!socket.active) {
            return;
        }

        let errorReported = false;

        const connectErrorListener = () => {
            if (!errorReported) {
                error(translation.connectionToServerError);
                errorReported = true;
            }
        };

        socket.on("connect_error", connectErrorListener);

        return () => {
            socket.off("connect_error", connectErrorListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, socket]);

    function getDimensions() {
        if (canvasRef.current) {
            let { width, height } = canvasRef.current.getBoundingClientRect();
            return { width, height };
        }
        return { width: 0, height: 0 };
    }

    useEffect(() => {
        if (canvasRef.current) {
            setDimensions(getDimensions());
        }
    }, [canvasRef]);

    // Dimension of the video
    useEffect(() => {
        const updateDimensions = () => {
            if (canvasRef.current) {
                let { width, height } = getDimensions();
                width = Math.floor(width);
                height = Math.floor(height);
                setDimensions({ width, height });
                resizeAction(width, height);
            }
        };
        updateDimensions();
        // Attach resize event listener
        window.addEventListener("resize", updateDimensions);
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rightArrowDisabled = !actionsStatus.navigationButtons || processingClick || !lastBrowserAction || lastBrowserAction === BrowserNavigation.FORWARD;

    const leftArrowDisabled = !actionsStatus.navigationButtons || processingClick;

    return (
        <div className="flex flex-col grow px-4">
            <main className="flex flex-col grow rounded-lg border-medium-grey">
                <div className="flex h-full w-full flex-col rounded-lg bg-browser border p-1">
                    <div className="flex flex-col space-y-1 p-2">
                        <div className="flex w-full items-center justify-between space-x-2">
                            <div className="flex space-x-2">
                                {sessionStarted && (
                                    <NavigationButtons
                                        sessionStarted={sessionStarted}
                                        leftArrowDisabled={leftArrowDisabled}
                                        rightArrowDisabled={rightArrowDisabled}
                                        navigationAction={navigationAction}
                                        setShowEvents={setShowEvents}
                                    />
                                )}
                            </div>
                            <input
                                className="border rounded-full scroll-mt-24 focus:border-primary outline-none px-2 py-1 w-full"
                                type="text"
                                value={remoteUrl}
                                onChange={e => setRemoteUrl(e.target.value)}
                                onKeyDown={startSession}
                                disabled={sessionStarted}
                                autoFocus
                            />
                            <RecordingTools
                                sessionStarted={sessionStarted}
                                actionsStatus={actionsStatus}
                                onArea={onArea}
                                endSession={endSession}
                                processingClick={processingClick}
                            />
                        </div>
                        {sessionStarted && <SessionDuration sessionDuration={sessionDuration} />}
                    </div>
                    <div id="lpr-video" className="relative flex grow bg-browser rounded-lg rounded-t-none p-1">
                        {showEvents && <EventHistory eventHistory={eventHistory} />}
                        <Video
                            canvasRef={canvasRef}
                            dimensions={dimensions}
                            sessionStarted={sessionStarted}
                            progressionText={progressionText}
                            errorDuringInitialization={errorDuringInitialization}
                        />
                    </div>
                </div>
            </main>
        </div>
    );
}
