import { useI18n, useToast } from "@vaultinum/app-sdk";
import { InteractionEvent, Report } from "@vaultinum/vaultinum-legal-proof-api";
import { Dispatch, RefObject, SetStateAction, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { REPORT_MANAGER_PAGE } from "../AppRouter";
import { useSession } from "../context";
import { Translation } from "../i18n";
import { ActionStatus, Notifications } from "../model";
import { AreaDraw } from "../tool";

export interface Handler<T> {
    eventName: string;
    onEvent: (data: T) => void;
}

interface Callbacks {
    setRemoteUrl: (url: string) => void;
    setSessionDuration: (sessionDuration: string) => void;
    setEventHistory: Dispatch<SetStateAction<Notifications.Event[]>>;
    setSessionStarted: (sessionStarted: boolean) => void;
    setProcessingClick: (processingClick: boolean) => void;
    setActionsStatus: (actionStatus: ActionStatus) => void;
    setProgressionText: (progressionText: string) => void;
    setErrorDuringInitialization: (error: boolean) => void;
}

const taskMessages = (translation: Translation): Record<string, string> => ({
    [Report.TaskType.START_SESSION]: translation.sessionStartFeedback.startSession,
    [Report.TaskType.BROWSER_SETUP]: translation.sessionStartFeedback.browserSetup,
    [Report.TaskType.OPEN_PAGE]: translation.sessionStartFeedback.openPage,
    [Report.TaskType.CAPTURE_FIRST_PAGE]: translation.sessionStartFeedback.captureFirstPage,
    [Report.TaskType.CAPTURE_HTML]: translation.sessionStartFeedback.captureHtml
});

export const useSessionHandlers = <T>(
    canvasRef: RefObject<HTMLCanvasElement>,
    sessionStarted: boolean,
    userUid: string,
    callbacks: Callbacks
): Handler<T>[] => {
    const { socket } = useSession();
    const { info, error } = useToast();
    const { translation } = useI18n<Translation>();
    const navigate = useNavigate();

    const {
        setRemoteUrl,
        setSessionDuration,
        setSessionStarted,
        setEventHistory,
        setProcessingClick,
        setActionsStatus,
        setProgressionText,
        setErrorDuringInitialization
    } = callbacks;

    const handleError = useCallback(
        (message: string) => {
            setErrorDuringInitialization(true);
            error(message);
        },
        [error, setErrorDuringInitialization]
    );

    const onFrame = useCallback(
        (data: { images: string[] }) => {
            const canvas = canvasRef.current;
            if (!canvas) {
                return;
            }

            const context = canvas.getContext("2d");
            if (!context) {
                return;
            }

            const { width, height, left } = canvas.getBoundingClientRect();
            const offset = Math.ceil(left - canvas.offsetLeft);

            data.images.forEach((base64Image, index) => {
                const rowHeight = height / data.images.length;
                const image = new Image();
                image.onload = () => context.drawImage(image, 0, index * rowHeight, width + offset, rowHeight);
                image.src = `data:image/webp;base64,${base64Image}`;
            });

            setSessionStarted(true);
        },
        [canvasRef, setSessionStarted]
    );

    const onUrl = useCallback((data: { url: string }) => setRemoteUrl(data.url), [setRemoteUrl]);

    const onCursor = useCallback(
        (data: { cursor: string }) => {
            const canvas = canvasRef.current;
            if (canvas) {
                canvas.style.cursor = data.cursor;
            }
        },
        [canvasRef]
    );

    const onInvalidUrl = useCallback(() => {
        handleError(translation.invalidUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleError]);

    const onSessionDuration = useCallback((duration: string) => setSessionDuration(duration), [setSessionDuration]);

    const onEventDone = useCallback(
        ({ id, date, label }: Notifications.Event) => {
            setEventHistory(prev => [
                {
                    date,
                    label
                },
                ...prev
            ]);

            if (id === InteractionEvent.AREA) {
                info(translation.screenCapturedSuccessfully);
            }

            if (id === InteractionEvent.FULL_PAGE) {
                info(translation.fullPageAddedToReport);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onNewTab = useCallback(() => info(translation.newTabNotSupported), [info, translation]);

    const onEventError = useCallback(() => error(translation.errorWhileProcessingEvent), [error, translation]);

    const onPauseInteractions = useCallback(
        ({ fullPage }: { fullPage: boolean }) => {
            if (sessionStarted) {
                setProcessingClick(true);
                new AreaDraw(socket, userUid, setActionsStatus, "wait");
            }
            socket.emit(InteractionEvent.PAUSE_INTERACTIONS, { userUid });
            if (fullPage) {
                info(translation.pauseInteractionsDuringFullPageCapture);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [info, translation, setProcessingClick, socket, userUid, setActionsStatus]
    );

    const onResumeInteractions = useCallback(
        ({ fullPage }: { fullPage: boolean }) => {
            setProcessingClick(false);
            document.getElementById("new-canvas")?.remove();
            socket.emit(InteractionEvent.RESUME_INTERACTIONS, { userUid });
            if (fullPage) {
                info(translation.resumeInteractionsAfterFullPageCapture);
            }
        },
        [info, translation, setProcessingClick, socket, userUid]
    );

    const onBrowserError = useCallback(() => {
        handleError(translation.browserError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleError]);

    const onTaskUpdate = useCallback(
        (data: { key: string }) => {
            const text = taskMessages(translation)[data.key];
            if (text) {
                setProgressionText(text);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onInvalidReportMetadata = useCallback(
        (data: { name: string }) => {
            error(data.name === "reportTitle" ? translation.reportTitleNoSpecialChars : translation.reportRequesterNoSpecialChars);
            navigate(REPORT_MANAGER_PAGE);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [error, navigate]
    );

    const handlers = [
        {
            eventName: "frame",
            onEvent: (data: { images: string[] }) => onFrame(data)
        },
        {
            eventName: "url",
            onEvent: (data: { url: string }) => onUrl(data)
        },
        {
            eventName: "cursor",
            onEvent: (data: { cursor: string }) => onCursor(data)
        },
        {
            eventName: "invalid-url",
            onEvent: () => onInvalidUrl()
        },
        {
            eventName: "session-duration",
            onEvent: (data: string) => onSessionDuration(data)
        },
        {
            eventName: "event-done",
            onEvent: (data: Notifications.Event) => onEventDone(data)
        },
        {
            eventName: "new-tab",
            onEvent: () => onNewTab()
        },
        {
            eventName: "event-error",
            onEvent: () => onEventError()
        },
        {
            eventName: "pause-interactions",
            onEvent: (data: { fullPage: boolean }) => onPauseInteractions(data)
        },
        {
            eventName: "resume-interactions",
            onEvent: (data: { fullPage: boolean }) => onResumeInteractions(data)
        },
        {
            eventName: "task-update",
            onEvent: (data: { key: string }) => onTaskUpdate(data)
        },
        {
            eventName: "browser-error",
            onEvent: () => onBrowserError()
        },
        {
            eventName: "invalid-report-metadata",
            onEvent: (data: { name: string }) => onInvalidReportMetadata(data)
        }
    ];

    return handlers as Handler<T>[];
};
