import { Button, InfoIcon, useI18n, useToast } from "@vaultinum/app-sdk";
import { InteractionEvent } from "@vaultinum/vaultinum-legal-proof-api";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { REPORT_MANAGER_PAGE } from "../../../../AppRouter";
import { useAuth } from "../../../../auth";
import { confirmationToast, Cross, PageIcon, Square } from "../../../../components";
import { useSession } from "../../../../context";
import { Translation } from "../../../../i18n";
import { IconType } from "../../../../model";
import AreaButton from "./AreaButton";

export default function RecordingTools({
    sessionStarted,
    actionsStatus,
    onArea,
    endSession,
    processingClick
}: {
    sessionStarted: boolean;
    actionsStatus: { areaIcon: IconType; endButton: boolean };
    onArea: () => void;
    endSession: () => void;
    processingClick: boolean;
}) {
    const { socket } = useSession();
    const { user } = useAuth();
    const navigate = useNavigate();
    const { translation } = useI18n<Translation>();
    const { areaIcon, endButton } = actionsStatus;
    const [cancelHover, setCancelHover] = useState(false);
    const [endHover, setEndHover] = useState(false);
    const { info } = useToast();

    function onConfirm() {
        if (socket.active) {
            socket.disconnect();
        }
        navigate(REPORT_MANAGER_PAGE);
    }

    function showHelpModal() {
        info(
            <div className="flex flex-col">
                <span>{translation.needHelp}</span>
                <span>{process.env.REACT_APP_EVIDENCY_SUPPORT_EMAIL}</span>
            </div>
        );
    }

    function onFullPageScreenshot() {
        socket.emit(InteractionEvent.FULL_PAGE, { userUid: user?.uid });
    }

    return (
        <div className="flex space-x-2 items-center">
            <Toaster />
            <button onClick={showHelpModal}>
                <InfoIcon className="h-6 cursor-pointer fill-primary" />
            </button>
            {sessionStarted && (
                <>
                    <AreaButton areaIcon={areaIcon} onArea={onArea} processingClick={processingClick} />
                    <PageIcon onClick={onFullPageScreenshot} isDisabled={processingClick} />
                </>
            )}
            <Button
                icon={<Cross disabled={processingClick} isHover={cancelHover} />}
                label={translation.cancel}
                onClick={() => confirmationToast({ onConfirm, message: translation.confirmCancelReport })}
                disabled={processingClick}
                onMouseEnter={() => setCancelHover(true)}
                onMouseLeave={() => setCancelHover(false)}
            />
            {sessionStarted && (
                <Button
                    icon={<Square disabled={!endButton || processingClick} isHover={endHover} />}
                    label={translation.end}
                    onClick={() => confirmationToast({ onConfirm: endSession, message: translation.confirmEndReport })}
                    disabled={!endButton || processingClick}
                    variant="danger"
                    onMouseEnter={() => setEndHover(true)}
                    onMouseLeave={() => setEndHover(false)}
                />
            )}
        </div>
    );
}
